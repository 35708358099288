import React from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'

const AboutUs = ({ aboutus }) => (
  <div className='about-area pd-bottom-90'>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 mb-4 mb-lg-0'>
          <div className='shape-image-list-wrap'>
            <div className='shape-image-list left-top'>
              <Img
                className='shadow-img'
                image={getImage(aboutus.image)}
                alt='image'
              />
            </div>
          </div>
        </div>
        <div className='col-lg-6 align-self-center'>
          <div className='section-title pd-left mb-0'>
           {/* <h5 className='sub-title'>{aboutus.subtitle}</h5>*/}
            <h2 className='title'>{aboutus.title}</h2>
            <p>{aboutus.content}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AboutUs
