import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

const PageHeader = ({   children, heroImage }) => {
  const { contentfulHeroBadge } = useStaticQuery(graphql`
    {
      contentfulHeroBadge(title: { eq: "others" }) {
        image {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  let defaultBgImage = heroImage ? heroImage : contentfulHeroBadge

  const image = getImage(defaultBgImage.image)

  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag='section'
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className='breadcrumb-area jarallax'
    >
      <div className='banner-overlay'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='breadcrumb-inner'>
              {/*<h1 className='page-title'>{headertitle}</h1>*/}
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PageHeader
