import React from 'react'

const Mission = ({ mission }) => (
  <div className='mission-vission-area pd-top-80 pd-bottom-70'>
    <div className='container'>
      <div className='row justify-content-center'>
        {mission.nodes.map((item, i) => (
          <div key={i} className='col-xl-3 col-lg-4 col-sm-6'>
            <div className='single-intro text-lg-left text-center'>
              <div className='text'>{item.number}</div>
              <div className='details'>
                <h4 className='title'>
                  <a href='#'>{item.title}</a>
                </h4>
                <p>{item.content.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Mission
