import React from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'

const Team = ({ team }) => (
  <div className='team-area bg-gray mg-top-90 pd-top-70 pd-bottom-90'>
    <div className='container'>
      <div className='section-title text-center'>
        <h2 className='title'>We Are A Dynamic Team</h2>
      </div>
      <div className='row'>
        {team.nodes.map((item, i) => (
          <div key={i} className='col-md-6 col-lg-4'>
            <div className='single-team text-center'>
              <div className='thumb'>
                <Img image={getImage(item.image)} alt='team' />
              </div>
              <div className='team-details'>
                <h4>{item.name}</h4>
                <span>{item.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Team
