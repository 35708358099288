import React from 'react'
import { FaHome } from 'react-icons/fa'

import bgImg from '../../../content/assets/img/bg/tennie-inspec.jpg'
import icon19 from '../../../content/assets/img/icons/19.png'

const ServiceTwo = ({ service, serviceItem }) => {
  const inlineStyle = {
    backgroundImage: 'url(' + bgImg + ')'
  }

  return (
    <div
      className='service-area service-area-about mg-bottom-100 pb-xl-5 pd-0'
      style={inlineStyle}
    >
      <div className='container'>
        <div className='section-title col-12 col-lg-6 my-6'>
          {/*<h5 className='sub-title'>{service.subtitle}</h5>*/}
          {<h2 className='title'>{service.title}</h2>}
         {/* <p>{service.content.content}</p>*/}
        </div>
        <div className='service-slider-2 row pb-xl-5 pd-0'>
          {serviceItem.nodes.map((item, i) => (
            <div key={i} className='item col-md-6 col-lg-4 col-12 my-2 '>
              <div className='single-intro text-center bg-white shadow-sm rounded'>
                <div className='thumb'>
                  <FaHome className='text-white h4' />
                </div>
                <div className='details'>
                  <h4 className='title'>
                    <a href={item.url}>{item.title}</a>
                  </h4>
                  {/*<p>{item.content.content}</p>*/}
                  <div>
                    {item.content2.map((item, i) => 
                    
                    <div key={i}>
                      <p>{item}</p>

                      </div>
                      )}
                    </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServiceTwo
