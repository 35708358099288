import React from 'react'
import { graphql, Link } from 'gatsby'
import Mission from '../components/AboutPage/mission'
import AboutUs from '../components/AboutPage/about-us'
import ServiceTwo from '../components/AboutPage/service-two'
import Team from '../components/AboutPage/team'
import Layout from '../components/LayoutView'
import PageHeader from '../components/common/pageHeader'
import { BreadcrumbJsonLd, LocalBusinessJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from "../components/PropertyDetails/breadcrumb";
/*import Client from '../components/AboutPage/client'*/


const About = ({ data }) => {
  const items = [
    {to:'/', label: 'Home'},
    {label: 'About'}
  ]
  return (
    <Layout>
      <GatsbySeo
        title='Find out more about us'
        description='Find out more about us'
        openGraph={{
          title: 'Find out more about us',
          description: 'Find out more about us',
          url: 'https://www.ownahome.ng/about',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'find out about us'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />

<LocalBusinessJsonLd
      type='Office'
      id='http://owanhome.ng'
      name="Ownahome's Physical Office"
      description="Owning a home does not have to make you lose sleep, property recommendations in Lagos, Abuja Nigeria and Dubai"
      url='https://www.ownahome.ng'
      telephone='+2348101536132'
      address={{
        streetAddress: '26 Ina Obasi Street Ogudu, Lagos',
        addressLocality: 'Ogudu',
        addressRegion: 'NG',
        postalCode: '100212',
        addressCountry: 'NG',
      }}
      geo={{
        latitude: '6.572684637137086', 
        longitude: '3.3874291679890547',
      }}
      images={[
        'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              
      ]}
    />

<BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Books',
          item: 'https://ownahome.ng',
        },
        {
          position: 2,
          name: 'About',
          item: 'https://owanhome.ng/about',
        },
      ]}
    />



      <PageHeader headertitle='About Us' heroImage={data.heroImage}>
        <Breadcrumb >
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>
        
      </PageHeader>
      <div>
        <Mission mission={data.mission} />
        <AboutUs aboutus={data.aboutus} />
        <ServiceTwo service={data.service} serviceItem={data.serviceItem} />
        <Team team={data.team} />
       {/* <Client client={data.client} />*/}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    heroImage: contentfulHeroBadge(title: { eq: "about" }) {
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    aboutus: contentfulAboutSectionA {
      title
      subtitle
      image {
        gatsbyImageData(layout: FIXED, height:400)
      }
    }
    service: contentfulAboutSectionB {
      title
      subtitle
      content {
        content
      }
    }
    mission: allContentfulMission(sort: { order: ASC, fields: number }) {
      nodes {
        number
        title
        content {
          content
        }
      }
    }
    serviceItem: allContentfulAboutSectionBItems {
      nodes {
        title
        content2
        content {
          content

        }
      }
    }
    team: allContentfulAboutTeam {
      nodes {
        name
        image {
          gatsbyImageData(layout: FULL_WIDTH, height: 1000)
        }
        designation
      }
    }
    client: allContentfulAboutCustomer {
      nodes {
        image {
          gatsbyImageData(layout: FIXED)
        }
        name
        designation
      }
    }
  }
`

export default About
